import { useState, useEffect, useReducer } from "react"
import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import { message } from "antd"
import Head from "next/head"
import { useRouter } from "next/router"
import { analytics, API, ChatProvider, firebase } from "@project/shared"
import { QueryClient, QueryClientProvider } from "react-query"
import { useTranslation } from "react-i18next"
import {
  AuthProvider,
  authReducer,
  getUserMetadata,
  initialState,
} from "../utils"
import "../utils/css-imports"
import "../styles.css"
import { GlobalStyle } from "../utils/globalstyle"
import { CloseCircleFilled } from "@ant-design/icons"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(authReducer, initialState)
  const result = firebase.auth().currentUser

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url)
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Skeleton-Admin",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        window.scrollTo(0, 0)
        logEvent(url)
      })

      logEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logEvent)
      }
    }
  }, [])

  useEffect(() => {
    const clearLocalJobFormData = () => {
      if (
        localStorage.getItem("jobFormState") === "1" &&
        (routers.asPath !== "/jobs/add" ||
          routers.asPath.indexOf("/jobs/edit") === -1)
      ) {
        localStorage.removeItem("jobFormState")
        localStorage.removeItem("jobForm")
      }
    }
    routers.events.on("routeChangeComplete", clearLocalJobFormData)
    return () => {
      routers.events.off("routeChangeComplete", clearLocalJobFormData)
    }
  }, [routers.events])

  const injectCurrentUser = async (
    token?: string,
    user?: firebase.User | null
  ) => {
    let result: firebase.User | null
    try {
      if (token) {
        await firebase.auth().signInWithCustomToken(token)
        result = firebase.auth().currentUser
      } else {
        result = user
      }
      if (!result) return
      const { uid, role } = await getUserMetadata(result)
      if (role.startsWith("agent")) {
        const data = await API.get(`/agent-members/${uid}`, {
          params: { is_fb_uid: true },
        })
        // const hasUnseenMessages = await API.get(
        //   `/admin-chat/check-for-unseen-messages`
        // )

        dispatch({
          type: "SET_CURRENT_USER",
          payload: {
            // hasUnseenMessages: hasUnseenMessages?.data,
            ...data?.data,
          },
        })
      } else {
        throw new Error("Invalid role")
      }
      dispatch({
        type: "SET_USER",
        payload: { result, uid, role },
      })
    } catch (error) {
      firebase.auth().signOut()
      dispatch({ type: "SET_USER" })
      message.error({
        content: t("Unauthorized user"),
        key: "39",
        icon: <CloseCircleFilled onClick={() => message.destroy("39")} />,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!routers.isReady) return
    const custom_token = routers.query?.custom_token
    if (custom_token) {
      const token = String(custom_token)
      firebase.auth().signOut() // Fixes: multiple tab login
      injectCurrentUser(token)
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        injectCurrentUser("", user)
      })
    }
  }, [routers.isReady])

  useEffect(() => {
    if (routers.pathname === "/login") {
      /*
      Caution:
      We can't use one function for this code, here we're explicitly checking for user and then inject the current user. Above, we are just injecting the user.
      If we try to use it with just single function then, login page continues loading and never displays the UI.
      */
      firebase.auth().onAuthStateChanged((user) => {
        user && injectCurrentUser("", user)
      })
    }
  }, [routers.pathname])

  const refreshCurrentUser = () => {
    if (state.user?.result != firebase.auth().currentUser) {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener("focus", refreshCurrentUser)
    return () => {
      window.removeEventListener("focus", refreshCurrentUser)
    }
  })
  useEffect(() => {
    const clearLocalAgentJobFormData = () => {
      if (
        localStorage.getItem("agentJobFormState") === "1" &&
        (routers.asPath !== "/job-managemet/add" ||
          routers.asPath.indexOf("/job-managemet/edit") === -1)
      ) {
        localStorage.removeItem("agentJobFormState")
        localStorage.removeItem("agentJobForm")
      }
    }
    routers.events.on("routeChangeComplete", clearLocalAgentJobFormData)
    return () => {
      routers.events.off("routeChangeComplete", clearLocalAgentJobFormData)
    }
  }, [routers.events])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Sales Agent</title>
        </Head>
        <GlobalStyle />
        <AuthProvider value={{ loading, result, state, dispatch }}>
          <ChatProvider>
            <Component {...pageProps} />
          </ChatProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
